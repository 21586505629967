import { CompetitionsPageContent } from '@/components/Competition'
import { useLanguage } from '@/hooks/Contexts'
import Head from 'next/head'
import { FC, Fragment } from 'react'

export const Home: FC = () => {
  const { navigation: navStr } = useLanguage()
  return (
    <Fragment>
      <Head>
        <meta name='description' content={navStr.meta.home} key='description' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <CompetitionsPageContent dataFetched={null} />
    </Fragment>
  )
}
export default Home

/**
 * DEPRECATED
 * @returns
 */
// function Homepage() {
//   const { navigation: navStr, errors: errStr, competitions: compStr } = useLanguage()
//   const router = useRouter()

//   useEffect(() => {
//     if (!router.isReady) return
//     if (Number(router.query.error) == 1011) {
//       if (!toast.isActive(1011) && !toast.isActive('1011')) {
//         toast.error(appUtils.getErrorMsgByCode('1011', errStr), { toastId: 1011 }) // prevent duplicate
//       }
//       router.replace('/', '/', { shallow: true }) // clear query without refreshing
//       router.asPath = '/'
//       delete router.query.error
//     }
//   }, [router.query.error])

//   return (
//     <Fragment>
//       <Head>
//         <meta name='description' content={navStr.meta.home} key='description' />
//         <meta name='viewport' content='width=device-width, initial-scale=1' />
//       </Head>
//       <div className='flex h-auto w-screen flex-col items-center justify-center overflow-hidden bg-[#050601] pb-10 md:h-screen md:pb-0'>
//         <div className='relative'>
//           <img
//             src='/assets/banner_home.webp'
//             className='pointer-events-none h-auto max-h-[calc(100vh-7rem)] w-full max-w-[1280px] object-cover mix-blend-lighten'
//           />
//           <div className='absolute inset-x-0 top-0 bottom-1/3 mx-auto max-h-[640px] max-w-[640px] animate-pulse-out-in mix-blend-color'>{svgs.halo_v1}</div>
//           <div className='absolute inset-x-0 top-0 bottom-1/3 mx-auto max-h-[640px] max-w-[640px] animate-pulse-in-out mix-blend-color'>{svgs.halo_v2}</div>
//         </div>

//         <AnimatedHeadline />
//         <button
//           type='button'
//           className='btn-rd-amber btn-hover-amber btn-width h-10'
//           onClick={() => router.push('/competitions')}
//           data-testid='btn-landing-enter'
//         >
//           {compStr.details}
//         </button>
//       </div>

//       <WebInfo />
//       <div className='contents md:block md:px-4 md:pb-6 xl:container'>
//         <WinnerCarousel />
//       </div>

//       <CompetitionsEndSoon />
//       <LatestWinners />
//       <HowToPlay />
//     </Fragment>
//   )
// }

// // animated homepage headline --> with a left-to-right sliding mask */
// const AnimatedHeadline: FC = () => {
//   const [mounted, setMounted] = useState<boolean>(false)
//   useEffect(() => {
//     setMounted(true)
//     return () => setMounted(false)
//   }, [])
//   return (
//     <div className='relative -mt-28 mix-blend-difference'>
//       <img src='/assets/instant_wins_with_rkings.png' className='pointer-events-none h-28 object-contain px-2 sm:px-4 xl:container' />
//       <div
//         id='home-headline-mask'
//         className={`absolute inset-0 h-28 bg-black px-2 transition-transform duration-[5000ms] sm:px-4 md:container${mounted ? ' translate-x-full' : ''}`}
//       ></div>
//     </div>
//   )
// }
